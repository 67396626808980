import * as React from "react";
import logo from './style/scopa.gif';
import localization from './localization';

export class Form extends React.PureComponent {
  state = {
    name: Math.random().toString(36).substr(2, 7),
    session: Math.random().toString(36).substr(2, 7)
  };
handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
render() {
    return (
      <form
        onSubmit={async e => {
          e.preventDefault();
          this.props.onSubmit(this.state);
        }}
      >
        <img src={logo} className="logo" />
        <h3>{localization.startScopa}</h3>
        <div>{localization.seesionId}: </div>
        <input
          name="session"
          placeholder={this.state.session}
          value={this.state.session}
          onChange={this.handleChange}
        />
        <br/>
        <br/>
        <button type="submit" className="startButton">{localization.start}</button>
      </form>
    );
  }
}