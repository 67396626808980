/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createScopa = /* GraphQL */ `
  mutation CreateScopa(
    $input: CreateScopaInput!
    $condition: ModelScopaConditionInput
  ) {
    createScopa(input: $input, condition: $condition) {
      id
      deck
      players {
        name
        cards
        scopas
        score
      }
      table
      createdAt
      updatedAt
    }
  }
`;
export const updateScopa = /* GraphQL */ `
  mutation UpdateScopa(
    $input: UpdateScopaInput!
    $condition: ModelScopaConditionInput
  ) {
    updateScopa(input: $input, condition: $condition) {
      id
      deck
      players {
        name
        cards
        scopas
        score
      }
      table
      createdAt
      updatedAt
    }
  }
`;
export const deleteScopa = /* GraphQL */ `
  mutation DeleteScopa(
    $input: DeleteScopaInput!
    $condition: ModelScopaConditionInput
  ) {
    deleteScopa(input: $input, condition: $condition) {
      id
      deck
      players {
        name
        cards
        scopas
        score
      }
      table
      createdAt
      updatedAt
    }
  }
`;
