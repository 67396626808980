/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateSingleScopa = /* GraphQL */ `
  subscription OnUpdateSingleScopa($id: ID!) {
    onUpdateSingleScopa(id: $id) {
      id
      deck
      players {
        name
        cards
        scopas
        score
      }
      table
      createdAt
      updatedAt
    }
  }
`;
export const onCreateScopa = /* GraphQL */ `
  subscription OnCreateScopa {
    onCreateScopa {
      id
      deck
      players {
        name
        cards
        scopas
        score
      }
      table
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateScopa = /* GraphQL */ `
  subscription OnUpdateScopa {
    onUpdateScopa {
      id
      deck
      players {
        name
        cards
        scopas
        score
      }
      table
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteScopa = /* GraphQL */ `
  subscription OnDeleteScopa {
    onDeleteScopa {
      id
      deck
      players {
        name
        cards
        scopas
        score
      }
      table
      createdAt
      updatedAt
    }
  }
`;
