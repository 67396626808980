import React, { useState } from 'react';
import './Scopa.css';
import cards from './style/CardsStyle1.js';
import Amplify from '@aws-amplify/core';
import config from './aws-exports';
import { updateTable, subscribeToScopa, playerPickup, shuffle } from './ScopaClient';
import getScore from './getScore';
import localization from './localization';

Amplify.configure(config);
let playerCount = 1;
let possition = 0;
let cardsOnHand;
let rounds = 0;
let turn = 0;
let table = [];
let previousTable = [];
let players = null;
let player = null;
let roundStarted = false;
let hand = [];
let initialTurn = 0;

function nextTurn() {
  turn++;
  turn %= playerCount;
  console.trace('next turn');
}

function isRoundOver() {
  return possition + 3 >= 40 && turn === initialTurn && table.length === 0;
}

function Scopa(props) {

  const [isinitialized, setinitialized] = useState(false);
  const [sessionId] = useState(props.state.id);
  const [deck, setDeck] = useState(props.state.deck);
  player = player || props.player;
  players = players || props.state.players;
  const [playerId] = useState(players.findIndex(p => p.name === player.name));
  const [captured, setCaptured] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [scopas, setScopas] = useState(0);
  const [tie, setTie] = useState(false);
  const [winner, setWinner] = useState(null);

  const deal = () => {
    console.log('deal', possition + 3 > 40, cardsOnHand, deck.slice(possition, possition + 3))
    if (possition + 3 > 40) return;
    cardsOnHand = 3;
    hand = deck.slice(possition, possition + 3);
    possition += playerCount * 3;
  }

  function init(players, player) {
    possition = 4 + playerId * 3;
    table = deck.slice(0, 4);
    deal();
    console.log('initialized')
    initialTurn = rounds % playerCount;
    turn = initialTurn;
    let bestScoreOver30 = 0;
    let bestScorersOver30 = [];
    for(player in players) {
      if (!player.score || player.score < 30) continue;
      if (player.score > bestScoreOver30) {
        bestScoreOver30 = player.score;
        bestScorersOver30 = [player];
        setTie(false);
        continue
      }
      if (player.score === bestScoreOver30) {
        bestScorersOver30.push(player);
        setTie(true);
      }
    }
    if (bestScorersOver30.length === 1) setWinner(bestScorersOver30[0].player);
    setinitialized(true);
    setCaptured([]);
    setScopas(0);
  }

  const onHandClick = async (card) => {
    roundStarted = true;
    nextTurn();
    const index = hand.indexOf(card);
    hand.splice(index, 1);
    table.push(card);
    cardsOnHand--;
    if (cardsOnHand === 0) deal();
    await updateTable(sessionId, table);
  }

  const onTableClick = async (card) => {
    previousTable = [...table];
    let mutableScopas = scopas;
    table.splice(table.indexOf(card), 1);
    captured.push(card);
    if (table.length === 0 && !(isRoundOver && playerId === players.length -1)) mutableScopas++;
    await updateTable(sessionId, table);
    players[playerId] = { ...player, cards: captured, scopas: mutableScopas };
    await playerPickup(sessionId, players);
    setScopas(mutableScopas);
  }

  const onSessionUpdate = async (session) => {
    console.log(session)
    players = session.players;
    player = session.players[playerId];
    if (playerId === 0 && isRoundOver()) onNextRound1stPlayer();
    if (table.length === 0 && session.table.length === 4) onNextRound();
    else if (session.table.length > table.length
      && !(session.table.length === previousTable.length
        && session.table.every((card, i) => previousTable[i] === card)))
      nextTurn();
    table = session.table;
    if (session.players.length !== playerCount) {
      playerCount = session.players.length;
      (async () => init(players, player))();
    }
    if (cardsOnHand === 0) deal();
    setDeck(session.deck);
  }

  const onNextRound = () => {
    if (!roundStarted) return;
    roundStarted = false;
    rounds++;
    console.log("do we init anything?");
    init(players, player);
  }

  const onNextRound1stPlayer = async () => {
    if (!roundStarted) return;
    onNextRound();
    players.forEach((p, i) => players[i] = { ...p, score: getScore(players, p, scopas), cards: [], scopas: 0 });
    await playerPickup(sessionId, players);
    await shuffle(sessionId);
  }

  function GameOver() {
    return (<div>
      <div>`${winner} ${localization.won.toUpperCase()}`</div>
      <div>{players.forEach(player => <div>{`${player.name}: ${player.score}`}</div>)}</div>
    </div>);
  }

  console.log('hand', hand)
  function GameScreen() {
    return (<div className='Scopa'>
      <div className='hud'>
        <span>{players.map((player, i) => <span className={turn === i ? 'turn' : 'player'} key={`hud${i}`}>{`${player.name}: ${player.score}`}</span>)}</span>
        <span>{tie ? <span> {`${localization.tie.toUpperCase()}!!!`} </span> : <span />}</span>
      </div>
      <header className='Scopa-header'>
        <div className='table'>
          {table.map(i => (
            <button disabled={((playerId + 1) % playerCount !== turn && possition + 3 <= 40)} className='CardButton' onClick={() => onTableClick(i)} key={`table${i}`}><img src={cards[i]} className='Card' alt='hello' /> </button>
          ))}
        </div>
        <div className='hand'>
          {hand.map(i => (
            <button disabled={playerId !== turn} className='CardButton' onClick={() => onHandClick(i)} key={`hand${i}`}><img src={cards[i]} className='Card' alt='hello' /> </button>
          ))}
        </div>
      </header>
    </div>);
  }

  if (!isinitialized) {
    init(players, player);
  }

  if (!subscription) setSubscription(subscribeToScopa(sessionId, onSessionUpdate));

  return winner ? <GameOver/> : <GameScreen/>;
}


export default Scopa;
