/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://hwh3l3m6xnb2pel4jllknskahe.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:93f0e610-5c80-437c-8dbf-63256511e370",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_HfCtaAYyU",
    "aws_user_pools_web_client_id": "7abvg2ppehhperjebti21d3t1p",
    "oauth": {
        "domain": "scopacb17c5fd-cb17c5fd-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.mariano-stanco.com/",
        "redirectSignOut": "https://www.mariano-stanco.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
