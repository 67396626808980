import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en: {
        players: 'players',
        seesionId: 'session id',
        startScopa: 'start Scopa',
        start: 'start',
        facebookSignin: 'sign in using Facebook',
        loginFailed: 'Login failed. Please contact the administrator.',
        tie: 'tie',
        won: 'won',
        contactMe: 'Contact me, Mariano Stanco, to request access at stancomariano@gmail.com.',
        purpose: 'I made this game to learn about app devlopment and play the game with friends and family.',
        aboutGame: 'This is the classic Scopa game.'
    },
    es: {
        players: 'jugadores',
        seesionId: 'numero de session',
        startScopa: 'comezar Scopa',
        start: 'comenzar',
        facebookSignin: 'Registrarse con Facebook',
        loginFailed: 'La registracion fallo. Por favor contacte al administrador.',
        tie: 'empate',
        won: 'gano',
        contactMe: 'Contacten me, Mariano Stanco, para pedir acceso en stancomariano@gmail.com.',
        purpose: 'Yo hice este juego para apprender sobre crear aplicaciones y jugar con familia y amigos.',
        aboutGame: 'Este es el clasico juego de la escoba.'
    }
});