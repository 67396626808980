/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getScopa = /* GraphQL */ `
  query GetScopa($id: ID!) {
    getScopa(id: $id) {
      id
      deck
      players {
        name
        cards
        scopas
        score
      }
      table
      createdAt
      updatedAt
    }
  }
`;
export const listScopas = /* GraphQL */ `
  query ListScopas(
    $filter: ModelScopaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScopas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deck
        players {
          name
          cards
          scopas
          score
        }
        table
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
