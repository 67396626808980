import { API, graphqlOperation } from 'aws-amplify';
import { createScopa, updateScopa } from './graphql/mutations';
import { getScopa } from './graphql/queries';
import { onUpdateSingleScopa } from './graphql/subscriptions';

export async function readSession(id) {
    return (await API.graphql(graphqlOperation(getScopa, { id }))).data.getScopa
}

export async function createSession(id, players) {
    const deck = shuffleCards();
    const table = deck.slice(0, 4);
    return (await API.graphql(graphqlOperation(createScopa, { input: { id, deck, players, table } }))).data.createScopa;
}

export async function addPlayers(id, players) {
    return (await API.graphql(graphqlOperation(updateScopa, { input: { id, players } }))).data.updateScopa;
}

export async function updateTable(id, table) {
    return (await API.graphql(graphqlOperation(updateScopa, { input: { id, table } }))).data.updateScopa;
}

export async function playerPickup(id, players) {
    return (await API.graphql(graphqlOperation(updateScopa, { input: {id, players } }))).data.updateScopa;
}

export async function shuffle(id) {
    const deck = shuffleCards();
    const table = deck.slice(0, 4);
    return (await API.graphql(graphqlOperation(updateScopa, { input: { id, deck, table } }))).data.updateScopa;
}

export async function subscribeToScopa(id, onSessionUpdate) {
    return API.graphql(graphqlOperation(onUpdateSingleScopa, { id })).subscribe({ next: scopa => onSessionUpdate(scopa.value.data.onUpdateSingleScopa) });
}

function shuffleCards() {
    let i = 0;
    return Array.from(Array(40), () => i++).sort(() => .5 - Math.random());
}

export function makePlayer(name) {
    return {
        name,
        cards: [],
        scopas: 0,
        score: 0
    }
}
