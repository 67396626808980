import React, { useState } from 'react';
import Amplify from '@aws-amplify/core'
import config from './aws-exports'
import { Form } from './Form';
import { Connect } from 'aws-amplify-react';
import { readSession, createSession, addPlayers, makePlayer } from './ScopaClient';
import Scopa from './Scopa';
import { Auth } from 'aws-amplify';
import logo from './style/scopa.gif';
import localization from './localization'

Amplify.configure(config);

function App() {
  const [session, setSession] = useState(null);
  const [player, setPlayer] = useState(null);
  const [user, setUser] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);


  if (session) return <Scopa state={session} player={player} />
  if (loginFailed) return <div className="loginFailed"><img src={logo} className="logo" /><div className="error">{`${localization.loginFailed} (Mariano)`}</div></div>
  if (window.location.pathname === "/aboutUs" || window.location.pathname === "/aboutUs/")
    return <div style={{ textAlign: 'center' }}><img src={logo} className="logo" /><br /><div>{localization.aboutGame}</div><div>{localization.purpose}</div><div>{localization.contactMe}</div><br /><a href={"/"}><button className="backButton">back</button></a></div >;

  async function getUser() {
    try {
      user || setUser(await Auth.currentAuthenticatedUser());
    } catch (e) {
      console.warn(e);
      setUser(false);
      return false;
    }
  }
  getUser();
  console.log(user)
  return (
    <div style={{ textAlign: 'center' }}>
      {!user
        ? <div>
          <div><img src={logo} className="logo" /></div>
          <div><button className='facebook' onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}><a href="#" className="fa fa-facebook" />{localization.facebookSignin}</button></div>
        </div>
        : <Connect >
          {() => (
            <Form onSubmit={async input => {
              try {
                const newPlayer = makePlayer(`${user.attributes.name} ${user.attributes.given_name}`);
                setPlayer(newPlayer);
                const remoteSession = await readSession(input.session)
                setSession(!remoteSession
                  ? await createSession(input.session, [newPlayer])
                  : await addPlayers(input.session, [...remoteSession.players, newPlayer]));
              } catch (e) {
                console.error(e);
                setLoginFailed(true);
              }
            }} />
          )}
        </Connect>
      }
      <br/>
      <a href={"/aboutUs"}><button className="aboutUsButton">About us</button></a>
    </div>
  );
}


export default App;
