
function setentaSuitScore(cards) {
    const ace = cards.indexOf(1);
    if (ace !== -1) cards[ace] = 5.5;
    return cards.filter(card => card < 8).reduce((max, current) => max > current ? max : current, 0);
}

function calculateSetenta(cards) {
    const golds = cards.filter(card => card < 10);
    const swords = cards.filter(card => 10 <= card && card < 20);
    const cups = cards.filter(card => 20 <= card && card < 30);
    const bastos = cards.filter(card => 30 <= card);
    if (golds.length === 0 || swords.length === 0 || cups.length === 0 || bastos.length === 0) return 0;

    // normalizing suits
    golds.map(card => card + 1);
    swords.map(card => card - 9);
    cups.map(card => card - 19);
    bastos.map(card => card - 29);

    console.log(golds, swords, cups, bastos)
    return setentaSuitScore(golds) + setentaSuitScore(swords) + setentaSuitScore(cups) + setentaSuitScore(bastos);
}

export default function getScore(players, player, scopas) {
    players.forEach(p => p.cards = [...(new Set(p.cards))])
    player.cards = [...(new Set(player.cards))];

    const playerGold = player.cards.filter(card => card < 10).length;
    const playerSetenta = calculateSetenta(player.cards);
    const playerCardCount = player.cards.length;

    const velo = player.cards.indexOf(6) === -1 ? 0 : 1;
    const gold = players.filter(p => p.cards.filter(card => card < 10).length >= playerGold).length === 1 ? 1 : 0;;
    const cardCount = players.filter(p => p.cards.length >= playerCardCount).length === 1 ? 1 : 0;
    const setenta = players.filter(p => calculateSetenta(p.cards) >= playerSetenta).length === 1 ? 1 : 0;
    console.log(player.score, setenta, gold, velo, cardCount, scopas)
    return player.score + setenta + gold + velo + cardCount + scopas;
}