import o1 from '../cards/1O.png';
import o2 from '../cards/2O.png';
import o3 from '../cards/3O.png';
import o4 from '../cards/4O.png';
import o5 from '../cards/5O.png';
import o6 from '../cards/6O.png';
import o7 from '../cards/7O.png';
import o10 from '../cards/10O.png';
import o11 from '../cards/11O.png';
import o12 from '../cards/12O.png';
import s1 from '../cards/1S.png';
import s2 from '../cards/2S.png';
import s3 from '../cards/3S.png';
import s4 from '../cards/4S.png';
import s5 from '../cards/5S.png';
import s6 from '../cards/6S.png';
import s7 from '../cards/7S.png';
import s10 from '../cards/10S.png';
import s11 from '../cards/11S.png';
import s12 from '../cards/12S.png';
import c1 from '../cards/1C.png';
import c2 from '../cards/2C.png';
import c3 from '../cards/3C.png';
import c4 from '../cards/4C.png';
import c5 from '../cards/5C.png';
import c6 from '../cards/6C.png';
import c7 from '../cards/7C.png';
import c10 from '../cards/10C.png';
import c11 from '../cards/11C.png';
import c12 from '../cards/12C.png';
import b1 from '../cards/1B.png';
import b2 from '../cards/2B.png';
import b3 from '../cards/3B.png';
import b4 from '../cards/4B.png';
import b5 from '../cards/5B.png';
import b6 from '../cards/6B.png';
import b7 from '../cards/7B.png';
import b10 from '../cards/10B.png';
import b11 from '../cards/11B.png';
import b12 from '../cards/12B.png';

export default [o1, o2, o3, o4, o5, o6, o7, o10, o11, o12,
                s1, s2, s3, s4, s5, s6, s7, s10, s11, s12,
                c1, c2, c3, c4, c5, c6, c7, c10, c11, c12,
                b1, b2, b3, b4, b5, b6, b7, b10, b11, b12];